import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import { momentDate } from '@/composables/date';
import axios from 'axios';

import * as auth from './touch-auth';
import * as orders from './touch-orders';
import * as news from './touch-news';
import * as processing from './touch-processing';
import * as contract from './touch-contract';
import * as report from './touch-report';
import * as common from './touch-common';
import * as staff from './touch-staff';
import * as customer from './touch-customer';
import * as marketing from './touch-marketing';
import * as kpi from './touch-kpi';
import * as branded from './touch-branded';
import * as visualisation from './touch-visualisation';
import * as vendor from './touch-vendor';
import * as setup from './touch-setup';
import * as stock from './touch-stock';
import * as connect from './touch-connect';
import * as socialFeeds from './touch-social-feeds';
import * as rack from './touch-rack';
import * as company from './touch-company';
import * as vehicle from './touch-vehicle';
import * as carrier from './touch-carrier';
import * as test from './touch-test';
import * as payment from './touch-payment';
import * as fake from './touch-fake';

import { useAuthStore } from '@/pinia/auth';
import { useBrandedStore } from '@/pinia/branded'
import { useBasketStore } from '@/pinia/basket';
import { useSandboxStore } from '@/pinia/sandbox'

import { resetPinia } from '@/composables/resetPinia';

export default class Touch {

  constructor(endpoint, imageBaseURL, router) {
    this.CONTRACT_TYPE_ENQUIRY = 1;
    this.CONTRACT_TYPE_QUOTE = 2;
    this.CONTRACT_TYPE_ORDER = 3;
    this.endpoint = endpoint;
    this.imageBaseURL = imageBaseURL;
    this.router = router;
    this.authStore = useAuthStore()
    this.brandedStore = useBrandedStore()
    this.basketStore = useBasketStore()
    this.sandboxStore = useSandboxStore()
    this.axios = axios
    this.prefix = 'pearl'

    Object.assign(this, auth);
    Object.assign(this, orders);
    Object.assign(this, news);
    Object.assign(this, processing);
    Object.assign(this, contract);
    Object.assign(this, report);
    Object.assign(this, common);
    Object.assign(this, staff);
    Object.assign(this, customer);
    Object.assign(this, marketing);
    Object.assign(this, kpi);
    Object.assign(this, branded);
    Object.assign(this, visualisation);
    Object.assign(this, vendor);
    Object.assign(this, setup);
    Object.assign(this, stock);
    Object.assign(this, connect);
    Object.assign(this, socialFeeds);
    Object.assign(this, rack);
    Object.assign(this, company);
    Object.assign(this, vehicle);
    Object.assign(this, carrier);
    Object.assign(this, test);
    Object.assign(this, payment);
    Object.assign(this, fake);

    this.setLogin(this.authStore?.authToken, this.authStore?.authTokenExpiry, this.authStore?.installation);

    const urlSuffix = 'bm-touch.co.uk'
    const regEx = new RegExp(`^(([^.]+)\\.)?(business|admin|portal)(\\.([^.]+))?\\.${urlSuffix.replace(/\./gi, '\\.')}$`);
    const matches = window.location.hostname.match(regEx);

    if (matches) {
      const type = matches[3];
      this.prefix = matches[2] ? matches[2] : type;
      const environment = matches[5];
      const domain = [this.prefix, 'server', environment].filter(Boolean).join('.');
      this.endpoint = `https://${domain}.${urlSuffix}/api/`;
      this.imageBaseURL = `https://${domain}.${urlSuffix}`;
    }

    if (this.endpoint === undefined) {
      throw new Error('Endpoint not defined');
    }
    if (this.imageBaseURL === undefined) {
      throw new Error('imageBaseURL not defined');
    }
    window.setTimeout(this.sessionTimeoutCheck.bind(this), 600000);
  }

  urlIs(prefix) {
    return this.prefix === prefix;
  }

  sessionTimeoutCheck() {
    if (this.tokenExpiry) {
      Object.keys(this.tokenExpiry).forEach((key) => {
        if (this.tokenExpiry[key]) {
          if (momentDate(this.tokenExpiry[key]).isBefore()) {
            this.token[key] = undefined;
            this.tokenExpiry[key] = undefined;
            this.router
              .push({ path:  this.authStore.login_url, query: { redirect: this.router.currentRoute.path } })
              .catch(() => {});
          } else if (momentDate(this.tokenExpiry[key]).subtract(6, 'hours').isBefore()) {
            this.authStore.GetJWT(key)
          }
        }
      });
    }
    window.setTimeout(this.sessionTimeoutCheck.bind(this), 600000);
  }

  parseResponseWith400(
    response,
    errorCallback,
    successCallback,
    successCodes = [200, 201, 202, 204, 400, 404],
  ) {
    const defaultCallback = (r) => ({
      ...r.data,
      code: response.status,
    });

    return this.parseResponse(
      response,
      errorCallback,
      successCallback || defaultCallback,
      successCodes,
    );
  }

   
  parseResponse(
    response,
    errorCallback,
    successCallback = (r) => r.data,
    successCodes = [200, 201, 202, 204],
  ) {
    if (response !== null) {
      if (successCodes.includes(response.status)) {
        return successCallback(response);
      }

      Sentry.captureEvent({
        message: `Status ${response.status} : ${response.config.url.replace('https://', '')}`,
        extra: {
          ajax_input: response.config.data,
          response: response?.data,
          status: response.status
        },
      });
    } else {
      Sentry.captureEvent({
        message: 'NULL RESPONSE',
      });
    }

    if (typeof errorCallback === 'function') {
      return errorCallback(response);
    }

    // const

    window.alertBox.fire({
      title: response?.data?.title || 'Unknown Error',
      text: response?.data?.detail || 'An unknown error occurred',
    });

    return errorCallback;
  }

  hasToken() {
    return this.token !== undefined;
  }

  setEndpoint(endpoint) {
    this.endpoint = endpoint;
  }

  setImageBaseURL(url) {
    this.imageBaseURL = url;
  }

   
  async download(url) {
    Cookies.remove('Bearer');
    await this.authGetCookie();
    const link = document.createElement('a');
    link.href = `/api/${url}`;

    document.body.appendChild(link);
    link.click();
  }

  async displayStream(url, options = {}) {
    const response = await this.authenticatedPost(
      url,
      {},
      {},
      {
        responseType: 'blob',
      },
    );
    return window.URL.createObjectURL(new Blob([response.data], options));
  }

  post(method, payload = {}, headers = {}) {
    return this.axios.post(this.endpoint + method, payload, {
      ...headers,
      validateStatus(status) {
        return status >= 200 && status < 500; // Resolve only if the status code is less than 500
      },
    });
  }

  authenticatedGet(method) {
    let url;

    if (method.endpoint) {
      url = method.endpoint + method.method;
    } else {
      url = this.endpoint + method;
    }
    return this.axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${this.token[this.installation]}`,
        },
      })
      .then((response) => response)
      .catch((errorResponse) => {
        if (errorResponse && errorResponse.response) {
          Sentry.captureEvent({
            message: `${errorResponse.response.status} ${errorResponse.response.statusText} : ${url}`,
            extra: {
              ajax_input: errorResponse.response.config.data,
              response: errorResponse.response.data,
              status: errorResponse.response.status,
            },
          });
        } else {
          Sentry.captureEvent({
            message: 'Status 0 no response',
            extra: {
              errorResponse: errorResponse
            },
          });
        }

        if (errorResponse.response.status === 401) {
          window.logoutPush = true;

          if (this.installation === 'public') {
            this.authStore.setBrandedMode(false)
            const launchURL = this.brandedStore.launchURL
            resetPinia(['basket', 'branded.slug'])
            this.router.push(launchURL).catch(() => {});
          } else if (this.router.currentRoute.path) {
            resetPinia(['basket', 'touch.isDealerNetworkPartnerOnly'])
            this.router.push(`${ this.authStore.login_url}?redirect=${this.router.currentRoute.path}`).catch(() => {});
          } else {
            resetPinia(['basket', 'touch.isDealerNetworkPartnerOnly'])
            this.router.push(`${ this.authStore.login_url}`).catch(() => {});
          }
        }

        return errorResponse.response;
      });
  }

  authenticatedPost(method, payload = {}, headers = {}, config = {}) {
    let url;

    if (method.endpoint) {
      url = method.endpoint + method.method;
    } else {
      url = this.endpoint + method;
    }
    return this.axios
      .post(url, payload, {
        ...config,
        headers: {
          Authorization: `Bearer ${this.token[this.installation]}`,
          ...headers,
        },
      })
      .then((response) => response)
      .catch((errorResponse) => {
        if (errorResponse && errorResponse.response) {
          Sentry.captureEvent({
            message: `${errorResponse.response.status} ${errorResponse.response.statusText} : ${url}`,
            extra: {
              ajax_input: errorResponse.response.config.data,
              response: errorResponse.response.data,
              status: errorResponse.response.status,
            },
          });
        } else {
          Sentry.captureEvent({
            message: 'Status 0 No Response',
            extra: {
              errorResponse: errorResponse,
            },
          });
        }

        if (errorResponse.response.status === 401) {
          window.logoutPush = true;

          if (this.installation === 'public') {
            this.authStore.setBrandedMode(false)
            const launchURL = this.brandedStore.launchURL
            resetPinia(['basket', 'branded.slug'])
            this.router.push(launchURL).catch(() => {});
          } else if (this.router.currentRoute.path) {
            resetPinia(['basket', 'touch.isDealerNetworkPartnerOnly'])
            this.router.push(`${ this.authStore.login_url}?redirect=${this.router.currentRoute.path}`).catch(() => {});
          } else {
            resetPinia(['basket', 'touch.isDealerNetworkPartnerOnly'])
            this.router.push(`${ this.authStore.login_url}`).catch(() => {});
          }
        }

        return errorResponse.response;
      });
  }
}